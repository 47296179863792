import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  cardWrapper,
  cardImage,
  cardTitle,
  cardLink,
  light,
  dark,
} from "./entry-card.module.css"
import { trackEvent } from "../../modules/analytics"

const EntryCard = ({
  title,
  link,
  image,
  imageUrl,
  variant = "light",
  ...props
}) => {
  if (image && imageUrl)
    throw new Error(
      `Provide either 'image' or 'imageUrl', not both, in EntryCard.`
    )

  if (!link) {
    return null
  }

  const articleComponent = (<article
    className={`${cardWrapper} ${variant === "light" ? light : dark}`}
    {...props}
  >
    {image && (
      <GatsbyImage
        className={cardImage}
        alt={image.title ? image.title : ""}
        image={image.gatsbyImageData}
      />
    )}
    {imageUrl && <img src={imageUrl} alt={title} width={70} />}

    <h1 className={cardTitle}>{title}</h1>
  </article>)

  if (link.startsWith("https://")) {
    return (
      <a href={link} title={title} className={cardLink} target="_blank" onClick={() => {
        trackEvent(`cta_entry_card_external`, { link_url: link })
      }}>
        {articleComponent}
      </a>
    )
  }

  return (
    <Link to={link} title={title} className={cardLink} onClick={() => {
      trackEvent(`cta_entry_card_internal`, { link_url: link })
    }}>
      {articleComponent}
    </Link>
  )
}

export default EntryCard
